<template>
    <BlockUI :blocked="blockComponent">
        <Toast />
        <ConfirmPopup></ConfirmPopup>
        <Sidebar v-model:visible="formVisible" :baseZIndex="1000" :autoZIndex="true" :dismissable="false"
            :showCloseIcon="false" @hide="closeForm" position="right"
            :class="'col-12 lg:col-8 md:col-10 sm:col-12 xs:col-12 mt-0'">
            <form @submit.prevent="login" @keydown="form.onKeydown($event)" enctype="multipart/form-data">
                <div class="mt-0 mb-0">
                    <div class="col-12 lg:col-12 mb-0">
                        <h3 class="mb-0">Manage {{ module_name }}</h3>
                        <p>You can add or update {{ module_name }} here. </p>
                    </div>
                    <Divider class="mt-0" />
                    <div class="grid modal-body">
                        <div class="col-12 lg:col-7 sm:col-12 mb-2">
                            <div>
                                <div class="col-12 lg:col-6 sm:col-12 mb-2" style="float:left">
                                    <label><span class="pi pi-star-fill important mr-1"></span>Name</label>
                                    <InputText id="name" placeholder="Name" v-model="form.name" type="text"
                                        class="full-width" />
                                    <HasError class="p-error" :form="form" field="name" />
                                </div>
                                <div class="col-12 lg:col-6 sm:col-12 mb-2" style="float:left"
                                    v-if="checkPermission(16, 'dropdown')">
                                    <label><span class="pi pi-star-fill important mr-1"></span>Company</label>
                                    <Dropdown CompaniesLoading :filter="true" :showClear="true"
                                        v-model="form.company_id" :options="companies" optionLabel="name"
                                        optionValue="id"
                                        :placeholder="CompaniesLoading ? 'Loading...' : 'Select Company'"
                                        class="full-width" />
                                    <HasError class="p-error" :form="form" field="company_id" />
                                </div>
                                <div class="col-12 lg:col-6 sm:col-12 mb-2" style="float:left"
                                    v-if="checkPermission(23, 'dropdown')">
                                    <label>Cargo Group<span v-if="checkPermission(23, 'insert')"
                                            class="supporting-btn-link" @click="showCargoGroupsForm = true;">+ Add
                                            New</span></label>
                                    <Dropdown CargoGroupsLoading :filter="true" :showClear="true"
                                        @change="getCargoGroupTypes($event)" v-model="form.group_id"
                                        :options="cargoGroups" optionLabel="name" optionValue="id"
                                        :placeholder="CargoGroupsLoading ? 'Loading...' : 'Select Group'"
                                        class="full-width" />
                                    <HasError class="p-error" :form="form" field="group_id" />
                                </div>
                                <!-- <div class="col-12 lg:col-6 sm:col-12 mb-2"  style="float:left"> 
                                    <label>Cargo Manifest<span v-if="permissions[24].includes('insert')" class="supporting-btn-link" @click="showCargoManifestForm=true;">+ Add New</span></label>
                                    <Dropdown ManifestsLoading :filter="true" :showClear="true"  v-model="form.manifest_id" :options="cargoManifests" optionLabel="name" optionValue="id"  :placeholder="ManifestsLoading ? 'Loading...' : 'Select Manifest'" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="manifest_id" />
                                </div> -->
                                <div class="col-12 lg:col-6 sm:col-12 mb-2" style="float:left"
                                    v-if="checkPermission(22, 'dropdown')">
                                    <label>Tax Rate<span v-if="checkPermission(22, 'insert')" class="supporting-btn-link"
                                            @click="showTaxRatesForm = true;">+ Add New</span></label>
                                    <MultiSelect TaxRatesLoading :filter="true" :showClear="true"
                                        v-model="form.tax_rates" :options="taxRates" optionLabel="name" optionValue="id"
                                        :placeholder="TaxRatesLoading ? 'Loading...' : 'Select Tax Rate'"
                                        class="full-width" />
                                    <HasError class="p-error" :form="form" field="tax_rates" />
                                </div>
                                <div class="col-12 lg:col-6 sm:col-12 mb-2" style="float:left">
                                    <label>Distance Unit</label>
                                    <Dropdown DistanceUnitsLoading :filter="true" :showClear="true"
                                        v-model="form.distance_unit_id" :options="distanceUnits" optionLabel="unit_name"
                                        optionValue="id"
                                        :placeholder="DistanceUnitsLoading ? 'Loading...' : 'Select Distance Unit'"
                                        class="full-width" />
                                    <HasError class="p-error" :form="form" field="distance_unit_id" />
                                </div>
                                <!-- <div class="col-12 lg:col-6 sm:col-6 mb-2" style="float:left"> 
                                    <label>Per Distance Unit Price/Fare</label>
                                    <InputNumber :min="0"   mode="currency" :currency="userData['company_currency_code'] && userData['company_currency_code'] != '' ? userData['company_currency_code'] : 'USD'" locale="en-US"  placeholder="Price" v-model="form.per_distance_unit_price" :min="0" showButtons class="full-width"/>  
                                    <HasError class="p-error" :form="form" field="per_distance_unit_price" /> 
                                </div>
                                <div class="col-12 lg:col-6 sm:col-6 mb-2" style="float:left"> 
                                    <label>Per Distance Unit Expense</label>
                                    <InputNumber :min="0"   mode="currency" :currency="userData['company_currency_code'] && userData['company_currency_code'] != '' ? userData['company_currency_code'] : 'USD'" locale="en-US"  placeholder="Price" v-model="form.per_distance_unit_expense" :min="0" showButtons class="full-width"/>  
                                    <HasError class="p-error" :form="form" field="per_distance_unit_expense" /> 
                                </div> 
                                <div class="col-12 lg:col-6 sm:col-6 mb-2" style="float:left"> 
                                    <label>Per Distance Unit Profit</label>
                                    <InputText disabled  :value="userData['company_currency_code'] && userData['company_currency_code'] != '' ? userData['company_currency_code']+' '+(+form.per_distance_unit_price-form.per_distance_unit_expense).toFixed(2) : 'USD ' + (+form.per_distance_unit_price-form.per_distance_unit_expense).toFixed(2) "  class="full-width"/>   
                                </div> -->
                                <!-- <div class="col-6 lg:col-6 sm:col-12 mb-2" style="float:left"> 
                                    <label>Luggage</label>
                                    <InputNumber :min="0"  id="luggage"  showButtons :min="0" step="0.1"  v-model="form.luggage" suffix=" KG" class="full-width" />
                                    <HasError class="p-error" :form="form" field="luggage" /> 
                                </div>    -->
                                <!-- <div class="col-12 lg:col-6 sm:col-12 mb-2"  style="float:left"> 
                                    <label>Stay Rule</label>
                                    <Dropdown StayRulesLoading :filter="true" :showClear="true"  v-model="form.vehicle_stay_id" :options="stayRules" optionLabel="name" optionValue="id"  :placeholder="StayRulesLoading ? 'Loading...' : 'Select Stay Rule'" class="full-width"/>
                                    <HasError class="p-error" :form="form" field="vehicle_stay_id" />
                                </div>  -->
                                <div class="col-6 lg:col-6 sm:col-12 mb-2" style="float:left">
                                    <label>Passengers Capacity</label>
                                    <InputNumber :min="0" id="passengers" showButtons step="1" :max="100"
                                        v-model="form.passengers" class="full-width" />
                                    <HasError class="p-error" :form="form" field="passengers" />
                                </div>
                                <div class="col-6 lg:col-6 sm:col-12 mb-2" style="float:left">
                                    <label>Child Seats</label>
                                    <InputNumber :min="0" id="childSeats" showButtons step="1" :max="100"
                                        v-model="form.child_seats" class="full-width" />
                                    <HasError class="p-error" :form="form" field="childSeats" />
                                </div>
                                <!-- <div class="col-6 lg:col-6 sm:col-12 mb-2" style="float:left"> 
                                    <label>Min Time Ahead</label>
                                    <InputNumber :min="0"  id="min_time_ahead"  showButtons :min="0" step="0.1"  v-model="form.min_time_ahead" suffix=" hours" class="full-width" />
                                    <HasError class="p-error" :form="form" field="min_time_ahead" /> 
                                </div>  -->
                                <!-- <div class="col-6 lg:col-6 sm:col-12 mb-2" style="float:left">  
                                    <div class="field-checkbox mt-4" style="mt-3">
                                        <Checkbox @click="changeShowInstantPrice()" value="true" v-model="showInstantPrice" :binary="true" />
                                        <label >Instant Pricing</label>
                                    </div>
                                </div> 
                                <div v-if="showInstantPrice" class="col-6 lg:col-6 sm:col-12 mb-2" style="float:left"> 
                                    <label>Min Price</label>
                                    <InputNumber :min="0"  id="min_price" v-model="form.min_price" mode="decimal" showButtons :min="0" step="0.1" :max="100"  class="full-width"/> 
                                    <HasError class="p-error" :form="form" field="min_price" /> 
                                </div>  
                                <div v-if="showInstantPrice" class="col-6 lg:col-6 sm:col-12 mb-2" style="float:left"> 
                                    <label>Max Price</label>
                                    <InputNumber :min="0"  id="max_price" v-model="form.max_price" mode="decimal" showButtons :min="0" step="0.1" :max="100"  class="full-width"/> 
                                    <HasError class="p-error" :form="form" field="max_price" /> 
                                </div>    -->
                                <div class="col-6 lg:col-6 sm:col-12 mb-2" style="float:left">
                                    <label>Price Calculation Unit</label>
                                    <Dropdown v-model="form.price_calculation_unit" :options="priceCalculationUnits"
                                        optionLabel="name" optionValue="id" placeholder="Select Unit"
                                        class="full-width" />
                                    <HasError class="p-error" :form="form" field="price_calculation_unit" />
                                </div>
                                <div class="col-6 lg:col-6 sm:col-12 mb-2" style="float:left">
                                    <label>Status</label>
                                    <Dropdown v-model="form.status" :options="RecordStatuses" optionLabel="name"
                                        optionValue="id" placeholder="Select Status" class="full-width" />
                                    <HasError class="p-error" :form="form" field="status" />
                                </div>
                                <div class="col-12 lg:col-12 sm:col-12 mb-2">
                                    <label>Description</label>
                                    <Textarea placeholder="Description" v-model="form.description" rows="5" cols="30"
                                        class="full-width" />
                                    <HasError class="p-error" :form="form" field="description" />
                                </div>

                            </div>
                        </div>
                        <div class="col-12 lg:col-5 sm:col-12" style="float:left">
                            <div>
                                <Panel class="mb-3 full-width" :header="'Cargo Type Units'" :toggleable="true">
                                    <div class="col-12 lg:col-12 sm:col-12 mb-2"
                                        v-for="(type, index) in form.cargoGroupTypes" :key="index">
                                        <label>{{ type.name }}</label>
                                        <InputText placeholder="Max Units" v-model="form.cargoGroupTypes[index].units"
                                            type="number" class="full-width" />
                                    </div>
                                </Panel>
                            </div>
                            <Button v-tooltip.top="'Additional Settings'"
                                :label="showBtnLabels ? 'Additional Settings' : ''"
                                @click="openAdditionalSettingsModal = true" icon="fa fa-gear"
                                :class="[[toolBarBtnSize == 'sm' ? 'p-button-sm' : 'p-button-md'], [toolBarBtnSize == 'lg' ? 'p-button-lg' : 'p-button-md']]"
                                class="p-button-raised p-button-primary mr-2" />
                            <!-- <div> 
                                <Panel class="mb-3 full-width" :header="'Additional Settings'" :toggleable="true">
                                    <div class="col-12 lg:col-12 sm:col-12 mb-2" v-for="(type, index) in form.cargoGroupTypes" :key="index"> 
                                        <label>{{type.name}}</label>
                                        <InputText  placeholder="Max Units" v-model="form.cargoGroupTypes[index].units"  type="number" class="full-width"/> 
                                    </div> 
                                </Panel>   
                            </div> -->
                        </div>

                        <!-- <div class="col-12 lg:col-6 sm:col-12 md:col-6">
                            <div> 
                                <Panel class="mb-3 full-width" :header="module_name+' images'" :toggleable="true">
                                    <div class="col-12 lg:col-12 sm:col-12 mb-2"> 
                                        <FileUpload name="images" :showUploadButton="false" :customUpload="true" @select="myUploader" :multiple="true" accept="image/*" :maxFileSize="1000000"> </FileUpload> 
                                    </div> 
                                </Panel>   
                                <Panel v-if="images.length" class="mb-3 full-width" header="Current images" :toggleable="true"  style="float:left">
                                    <div class="col-4 lg:col-4 sm:col-4 mb-2 text-center" v-for="(image,key) in images" :key="key" style="float:left">  
                                        <div>
                                            <img alt="image" class="form-image" :src="filesUrl+'vehicle_types/'+image.original_name" >
                                        </div>
                                        <div>
                                            <ConfirmPopup group="popup"></ConfirmPopup>
                                            <Button ref="popup" @click="confirmFileDeletion($event,image.id)" icon="pi pi-times" label="Remove" class="p-button-danger p-button-raised p-button-md" style="margin-left: .5em" />
                                        </div>   
                                    </div>
                                </Panel> 
                            </div>   
                        </div> -->
                    </div>
                    <Divider />
                    <div class="col-12 lg:col-12">
                        <Toolbar class="pl-0 pr-0">
                            <template v-slot:start>
                                <Button type="submit" label="Save" :loading="isSaving" icon="pi pi-check"
                                    @click="save()" class="p-button-raised p-button-lg p-button-success mr-2" />
                                <Button type="submit" label="Save and Close" :loading="isSaving" icon="pi pi-check"
                                    @click="saveAndClose = true, save()"
                                    class="p-button-raised p-button-lg p-button-info mr-2" />
                                <Button type="button" label="Reset" icon="pi pi-refresh"
                                    class="p-right p-button-raised p-button-lg p-button-warning mr-2"
                                    @click="resetForm()" />
                            </template>
                            <template v-slot:end>
                                <Button type="button" label="Cancel" icon="pi pi-times"
                                    class="p-right p-button-raised p-button-lg p-button-danger mr-2"
                                    @click="closeForm" />
                            </template>
                        </Toolbar>
                    </div>
                </div>
            </form>
        </Sidebar>

        <Sidebar v-model:visible="openAdditionalSettingsModal" :baseZIndex="1000" :autoZIndex="true"
            :dismissable="false" :showCloseIcon="true" @hide="openAdditionalSettingsModal = false" position="right"
            :class="'col-12 lg:col-8 md:col-10 sm:col-12 xs:col-12 mt-0'">
            <div class="mt-0 mb-0">
                <div class="col-12 lg:col-12 mb-0">
                    <h3 class="mb-0">Additional Settings</h3>
                    <p>Set additional settings for this vehicle type </p>
                </div>
                <Divider class="mt-0" />
                <div class="grid modal-body">
                    <div class="col-12 lg:col-7 sm:col-12 mb-2">
                        <Panel class="mb-3 full-width float-left" :header="'Distance Pricing'" :toggleable="true">
                            <div class="col-12 lg:col-12 sm:col-12 mb-0 float-left p-0"
                                v-for="(distanceRule, index) in form.distancePricingRules" :key="index">
                                <div class="col-12 lg:col-3 sm:col-12 mb-0 float-left">
                                    <label>From {{
                                        userData['company_distance_unit_code']?
                                    userData['company_distance_unit_code'] : 'KM'}}</label>
                                    <InputNumber :min="0" placeholder="Distance From"
                                        v-model="form.distancePricingRules[index].from" showButtons
                                        class="_full-width" />
                                </div>
                                <div class="col-12 lg:col-3 sm:col-12 mb-0 float-left">
                                    <label>To {{
                                        userData['company_distance_unit_code']?
                                    userData['company_distance_unit_code'] : 'KM'}}</label>
                                    <InputNumber :min="0" placeholder="Distance To"
                                        v-model="form.distancePricingRules[index].to" showButtons class="_full-width" />
                                </div>
                                <div class="col-12 lg:col-3 sm:col-12 mb-0 float-left">
                                    <label>{{ form.price_calculation_unit }} ({{
                                        userData['company_currency_code']?
                                    userData['company_currency_code'] : 'USD'}}) </label>
                                    <!-- <InputNumber   placeholder="Rate" v-model="distanceRule.rate"  showButtons class="full-width"/>  -->
                                    <InputNumber mode="currency"
                                        :currency="userData['company_currency_code'] && userData['company_currency_code'] != '' ? userData['company_currency_code'] : 'USD'"
                                        locale="en-US" placeholder="Rate"
                                        v-model="form.distancePricingRules[index].price" :min="0" showButtons
                                        class="_full-width" />
                                </div>
                                <div class="col-12 lg:col-3 sm:col-12 mb-0 float-left">
                                    <Button @click="form.distancePricingRules.splice(index, 1)"
                                        v-if="checkPermission(moduleId, 'delete')" v-tooltip.top="'Delete'"
                                        icon="pi pi-trash" class="p-button-lg p-button-raised p-button-danger mr-2 mt-3"
                                        style="padding: 7px 20px;" />
                                </div>
                            </div>
                            <div class="col-12 lg:col-10 sm:col-12 mb-0 float-left">
                                <Button @click="addDistancePricingRule()" label="Add New"
                                    v-if="checkPermission(moduleId, 'insert')" v-tooltip.top="'Add'" icon="pi pi-plus"
                                    class="p-button-lg p-button-raised p-button-success full-width mr-2 mt-3"
                                    style="padding: 7px 20px;" />
                            </div>
                            <Divider />
                            <div class="col-12 lg:col-12 sm:col-12 mb-0 p-0 float-left">
                                <div class="col-12 lg:col-5 sm:col-12 mb-2 float-left">
                                    <label>Price For Every Extra {{
                                        userData['company_distance_unit_code']?
                                    userData['company_distance_unit_code'] : 'KM'}}
                                        ({{
                                        userData['company_currency_code']? userData['company_currency_code'] :
                                        'USD'}})</label>
                                    <InputNumber mode="currency"
                                        :currency="userData['company_currency_code'] && userData['company_currency_code'] != '' ? userData['company_currency_code'] : 'USD'"
                                        locale="en-US" placeholder="Price" v-model="form.per_distance_unit_price"
                                        :min="0" showButtons class="_full-width" />
                                </div>
                            </div>
                        </Panel>
                        <Panel class="mb-3 full-width float-left" :header="'Stops/Stay Pricing'" :toggleable="true">
                            <div class="col-12 lg:col-12 sm:col-12 mb-2 float-left p-0">
                                <div class="col-12 lg:col-5 sm:col-12 mb-0 float-left">
                                    <label>Deafult Allowed Stops </label>
                                    <InputNumber :min="0" placeholder="Default Stops" v-model="form.default_stops"
                                        showButtons class="full-width" />
                                </div>
                                <div class="col-12 lg:col-5 sm:col-12 mb-0 float-left">
                                    <label>Price For Each Extra Stop ({{
                                        userData['company_currency_code']?
                                    userData['company_currency_code'] : 'USD'}})</label>
                                    <InputNumber mode="currency"
                                        :currency="userData['company_currency_code'] && userData['company_currency_code'] != '' ? userData['company_currency_code'] : 'USD'"
                                        locale="en-US" placeholder="Price" v-model="form.every_extra_stop_price"
                                        :min="0" showButtons class="_full-width" />
                                </div>
                            </div>
                        </Panel>
                        <Panel class="mb-3 full-width float-left" :header="'Helpers Pricing'" :toggleable="true">
                            <div class="col-12 lg:col-12 sm:col-12 mb-2 float-left p-0">
                                <div class="col-12 lg:col-5 sm:col-12 mb-0 float-left">
                                    <label>Deafult Allowed Helper </label>
                                    <InputNumber :min="0" placeholder="Default Stops" v-model="form.default_helpers"
                                        showButtons class="full-width" />
                                </div>
                                <div class="col-12 lg:col-5 sm:col-12 mb-0 float-left">
                                    <label>Price For Every Extra Helper Hourly ({{
                                        userData['company_currency_code']?
                                    userData['company_currency_code'] : 'USD'}})</label>
                                    <InputNumber mode="currency"
                                        :currency="userData['company_currency_code'] && userData['company_currency_code'] != '' ? userData['company_currency_code'] : 'USD'"
                                        locale="en-US" placeholder="Price" v-model="form.every_extra_helper_price"
                                        :min="0" showButtons class="_full-width" />
                                </div>
                            </div>
                        </Panel>
                        <Panel class="mb-3 full-width float-left" :header="'Lifts Pricing'" :toggleable="true">
                            <div class="col-12 lg:col-12 sm:col-12 mb-2 float-left p-0">
                                <div class="col-12 lg:col-5 sm:col-12 mb-0 float-left">
                                    <label>Fixed Price Increase if lift will be used
                                        ({{
                                        userData['company_currency_code']? userData['company_currency_code'] :
                                        'USD'}})</label>
                                    <InputNumber mode="currency"
                                        :currency="userData['company_currency_code'] && userData['company_currency_code'] != '' ? userData['company_currency_code'] : 'USD'"
                                        locale="en-US" placeholder="Price" v-model="form.fixed_price_increase_with_lift"
                                        :min="0" showButtons class="_full-width" />
                                </div>
                                <div class="col-12 lg:col-5 sm:col-12 mb-0 float-left">
                                    <label>Without Lift - Price For Each Floor ({{
                                        userData['company_currency_code']?
                                    userData['company_currency_code'] : 'USD'}})</label>
                                    <InputNumber mode="currency"
                                        :currency="userData['company_currency_code'] && userData['company_currency_code'] != '' ? userData['company_currency_code'] : 'USD'"
                                        locale="en-US" placeholder="Price" v-model="form.every_floor_price_with_no_lift"
                                        :min="0" showButtons class="_full-width" />
                                </div>
                            </div>
                        </Panel>
                        <Panel class="mb-3 full-width float-left" :header="'Booking Pricing'" :toggleable="true">
                            <div class="col-12 lg:col-12 sm:col-12 mb-2 float-left p-0">
                                <div class="col-12 lg:col-5 sm:col-12 mb-0 float-left">
                                    <label>Default Minimum Booking Hours </label>
                                    <InputNumber :min="0" placeholder="Price"
                                        v-model="form.default_minimum_booking_hours" showButtons class="full-width" />
                                </div>
                                <!-- <div class="col-12 lg:col-5 sm:col-12 mb-0 float-left" >
                                    <label>Price for every extra hour ({{userData['company_currency_code'] ? userData['company_currency_code'] : 'USD'}})</label>
                                    <InputNumber  mode="currency" :currency="userData['company_currency_code'] && userData['company_currency_code'] != '' ? userData['company_currency_code'] : 'USD'" locale="en-US"  placeholder="Price" v-model="form.exceeded_booking_hours_per_hour_price" :min="0" showButtons class="_full-width"/>  
                                </div> 
                                <div class="col-12 lg:col-5 sm:col-12 mb-0 float-left" >
                                    <label>Each helper's Price for every extra hour ({{userData['company_currency_code'] ? userData['company_currency_code'] : 'USD'}})</label>
                                    <InputNumber  mode="currency" :currency="userData['company_currency_code'] && userData['company_currency_code'] != '' ? userData['company_currency_code'] : 'USD'" locale="en-US"  placeholder="Price" v-model="form.per_helper_price_for_each_exceeded_booking_hours" :min="0" showButtons class="_full-width"/>  
                                </div>  -->
                                <div class="col-12 lg:col-10 sm:col-12 mb-0 float-left">
                                    <label>Booking will be considered urgent if order is placed before (Hours)</label>
                                    <InputNumber :min="0" placeholder="Default Stops"
                                        v-model="form.urgent_booking_hours" showButtons class="full-width" />
                                </div>
                            </div>
                        </Panel>
                        <Panel class="mb-3 full-width float-left" :header="'Van Arrival Fixed Time Pricing'"
                            :toggleable="true">
                            <div class="col-12 lg:col-12 sm:col-12 mb-2 float-left p-0"
                                v-for="(fixedTimeArrival, index) in form.fixedTimeArrivals " :key="index">
                                <div class="col-12 lg:col-2 sm:col-12 mb-0 float-left">
                                    <label>From Hours</label>
                                    <InputNumber :min="0" placeholder="From Hours"
                                        v-model="form.fixedTimeArrivals[index].from_hours" showButtons
                                        class="_full-width" />
                                </div>
                                <div class="col-12 lg:col-2 sm:col-12 mb-0 float-left">
                                    <label>To Hours</label>
                                    <InputNumber :min="0" placeholder="To Hours"
                                        v-model="form.fixedTimeArrivals[index].to_hours" showButtons
                                        class="_full-width" />
                                </div>
                                <div class="col-12 lg:col-5 sm:col-12 mb-0 float-left">
                                    <label>Fixed Amount Increase By ({{
                                        userData['company_currency_code']?
                                    userData['company_currency_code'] : 'USD'}}) </label>
                                    <InputNumber mode="currency"
                                        :currency="userData['company_currency_code'] && userData['company_currency_code'] != '' ? userData['company_currency_code'] : 'USD'"
                                        locale="en-US" placeholder="Price" v-model="form.fixedTimeArrivals[index].price"
                                        :min="0" showButtons class="_full-width" />
                                </div>
                                <div class="col-12 lg:col-3 sm:col-12 mb-0 float-left">
                                    <Button @click="form.fixedTimeArrivals.splice(index, 1)"
                                        v-if="checkPermission(moduleId, 'delete')" v-tooltip.top="'Delete'"
                                        icon="pi pi-trash" class="p-button-lg p-button-raised p-button-danger mr-2 mt-3"
                                        style="padding: 7px 20px;" />
                                </div>
                            </div>
                            <div class="col-12 lg:col-10 sm:col-12 mb-2 float-left">
                                <Button @click="addfixedTimeArrivalsRule()" label="Add New"
                                    v-if="checkPermission(moduleId, 'insert')" v-tooltip.top="'Add'" icon="pi pi-plus"
                                    class="p-button-lg p-button-raised p-button-success full-width mr-2 mt-3"
                                    style="padding: 7px 20px;" />
                            </div>
                        </Panel>

                    </div>
                    <div class="col-12 lg:col-5 sm:col-12" style="float:left">
                        <div>

                        </div>
                    </div>

                </div>
                <Divider />
                <div class="col-12 lg:col-12">
                    <Toolbar class="pl-0 pr-0">
                        <template v-slot:start>
                            <Button type="submit" label="Save" :loading="isSaving" icon="pi pi-check"
                                @click="openAdditionalSettingsModal = false"
                                class="p-button-raised p-button-lg p-button-success mr-2" />
                        </template>
                        <template v-slot:end>
                            <Button type="button" label="Cancel" icon="pi pi-times"
                                class="p-right p-button-raised p-button-lg p-button-danger mr-2"
                                @click="openAdditionalSettingsModal = false" />
                        </template>
                    </Toolbar>
                </div>
            </div>
        </Sidebar>
    </BlockUI>
    <VehicleTaxRateForm v-on:formClose="closeTaxRatesForm"
        v-if="showTaxRatesForm && (permissions[22].includes('insert'))" :recordId="0" :showForm="true" />
    <VehicleCargoGroupForm v-on:formClose="closeCargoGroupsForm"
        v-if="showCargoGroupsForm && (permissions[23].includes('insert'))" :recordId="0" :showForm="true" />
    <VehicleCargoManifestForm v-on:formClose="closeVehicleCargoManifestForm"
        v-if="showCargoManifestForm && (permissions[24].includes('insert'))" :recordId="0" :showForm="true" />
</template>
<script>
import VehicleTaxRateForm from '../../components/Vehicles/VehicleTaxRateForm.vue';
import VehicleCargoGroupForm from '../../components/Vehicles/VehicleCargoGroupForm.vue';
import VehicleCargoManifestForm from '../../components/Vehicles/VehicleCargoManifestForm.vue';
export default {
    data() {
        return {
            form: new this.Form({
                id: '',
                name: '',
                company_id: 0,
                manifest_id: 0,
                group_id: 0,
                tax_rates: [],
                cargoGroupTypes: [],
                vehicle_stay_id: 0,
                distance_unit_id: 0,
                per_distance_unit_expense: 0,
                passengers: 0,
                child_seats: 0,
                luggage: 0,
                min_price: 0,
                max_price: 0,
                instant_price: false,
                min_time_ahead: 0,
                status: 1,
                description: '',
                price_calculation_unit: '',
                every_extra_stop_price: 0,
                default_stops: 0,
                every_extra_helper_price: 0,
                default_helpers: 0,
                every_floor_price_with_no_lift: 0,
                fixed_price_increase_with_lift: 0,
                default_minimum_booking_hours: 0,
                exceeded_booking_hours_per_hour_price: 0,
                per_helper_price_for_each_exceeded_booking_hours: 0,
                urgent_booking_hours: 0,
                per_distance_unit_price: 0,
                distancePricingRules: [{ from: 0, to: 0, price: 0 }],
                fixedTimeArrivals: [{ from_hours: 0, to_hours: 0, price: 0 }]
            }),
            priceCalculationUnits: [{ name: 'Hourly', id: 'Hourly' }, { name: 'Per Distance Unit', id: 'Per Distance Unit' }],
            moduleId: 36,
            showInstantPrice: false,
            openAdditionalSettingsModal: false,
            images: [],
            CargoGroupsLoading: false,
            CompaniesLoading: false,
            CargoManifestsLoading: false,
            TaxRatesLoading: false,
            StayRulesLoading: false,
            DistanceUnitsLoading: false,
            module_name: 'Vehicle Type',
            isSaving: false,
            saveAndClose: false,
            recId: 0,
            loadingComponent: true,
            formVisible: false,
            cargoGroups: [],
            cargoManifests: [],
            taxRates: [],
            stayRules: [],
            distanceUnits: [],
            companies: [],
            showCargoGroupsForm: false,
            showCargoManifestForm: false,
            showTaxRatesForm: false,
        }
    },
    props: {
        showForm: {
            type: Boolean,
            required: true,
        },
        recordId: {
            type: Number,
        },
        isCopy: {
            type: Boolean,
        },
    },
    components: {
        VehicleCargoGroupForm,
        VehicleCargoManifestForm,
        VehicleTaxRateForm
    },
    created() {
    },
    computed: {
    },
    mounted() {
        this.resetForm();
        this.formVisible = this.showForm;
        this.recId = this.recordId;
        this.getAllRequestData();
    },
    methods: {
        addfixedTimeArrivalsRule() {
            this.form.fixedTimeArrivals.push({ from_hours: 0, to_hours: 0, price: 0 });
        },
        addDistancePricingRule() {
            this.form.distancePricingRules.push({ from: 0, to: 0, price: 0 });
        },
        closeTaxRatesForm() {
            this.getTaxRates();
            this.showTaxRatesForm = false;
        },
        closeVehicleCargoManifestForm() {
            this.getCargoManifests();
            this.showCargoManifestForm = false;
        },
        closeCargoGroupsForm() {
            this.getCargoGroups();
            this.showCargoGroupsForm = false;
        },
        changeShowInstantPrice() {
            if (!this.showInstantPrice) {
                this.form.instant_price = true;
            }
            else {
                this.form.instant_price = false;
            }
        },

        getAllRequestData() {
            this.$axios.all([this.getCargoGroups()])
                .then(() => {
                    this.updateRecord();
                    return this.getCompanies();
                })
                .then(() => {
                    return this.getCargoGroupTypes();
                    // return this.getCargoManifests();    
                })
                .then(() => {
                    return this.getDistanceUnits();
                })
                .then(() => {
                    // return this.getStayRules();    
                })
                .then(() => {
                    return this.getTaxRates();
                })
                .then(() => {
                    // this.updateRecord();    
                })
                .then(() => {
                    this.blockComponent = false;
                }).catch(() => {
                    //    this.getAllRequestData();
                })
                .finally(() => {
                });
        },
        async getCargoGroupTypes($event) {
            // console.log($event.value);
            // this.CargoGroupsLoading = true; 
            if (this.form.group_id) {
                var Vthis = this;
                await Vthis.axios.post('getAllVehiclesCargoTypes', { cargo_group_id: this.form.group_id, dropdown: true })
                    .then((response) => {
                        let responseStatus = this.printResponseResult(response, false);
                        if (responseStatus == 200) {
                            if (response.data.length > 0) {
                                Vthis.form.cargoGroupTypes = [];
                                response.data.forEach((rec) => {
                                    Vthis.form.cargoGroupTypes.push({ id: rec.id, name: rec.name, units: 0 });
                                })
                            }
                        }
                        else {
                            console.log(response);
                        }
                    })
                    .catch((error) => {
                        this.printResponseResult(error);
                    })
                    .finally(() => {
                        // this.CargoGroupsLoading = false; 
                    })
            }
            else {
                this.form.cargoGroupTypes = [];
            }
        },
        async getCargoGroups() {
            this.CargoGroupsLoading = true;
            var Vthis = this;
            await Vthis.axios.post('getAllVehiclesCargoGroups', { dropdown: true })
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        this.cargoGroups = response.data;
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                    this.CargoGroupsLoading = false;
                })

        },
        async getCargoManifests() {
            this.CargoManifestsLoading = true;
            var Vthis = this;
            await Vthis.axios.post('getAllVehiclesCargoManifests', { dropdown: true })
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        this.cargoManifests = response.data;
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                    this.CargoManifestsLoading = false;
                })

        },
        async getTaxRates() {
            this.TaxRatesLoading = true;
            var Vthis = this;
            await Vthis.axios.post('getAllVehiclesTaxRates', { dropdown: true })
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        this.taxRates = response.data;
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                    this.TaxRatesLoading = false;
                })

        },
        async getStayRules() {
            this.StayRulesLoading = true;
            var Vthis = this;
            await Vthis.axios.post('getAllVehiclesStayRules', { dropdown: true })
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        this.stayRules = response.data;
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                    this.StayRulesLoading = false;
                })

        },
        async getDistanceUnits() {
            this.DistanceUnitsLoading = true;
            var Vthis = this;
            await Vthis.axios.post('getAllDistanceUnits', { dropdown: true })
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        this.distanceUnits = response.data;
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                    this.DistanceUnitsLoading = false;
                })

        },
        async getCompanies() {
            this.CompaniesLoading = true;
            var Vthis = this;
            await Vthis.axios.post('getAllCompanies', { dropdown: true })
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        this.companies = response.data;
                        if (this.form.company_id == 0) {
                            this.form.company_id = response.data[0].id;
                        }
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                    this.CompaniesLoading = false;
                })

        },

        resetForm() {
            this.form.reset();
        },
        async save() {
            var Vthis = this;
            this.isSaving = true;
            await this.form
                .post(Vthis.$baseurl + 'api/saveVehicleType', Vthis.form)
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        // this.getRecords();
                        if (this.saveAndClose) {
                            this.saveAndClose = false;
                            setTimeout(() => { this.closeForm(); }, 1000);
                        }
                        Vthis.showMessage('Saved successfully', 'success',);
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                    this.isSaving = false;
                })
        },
        loadForm() {
            this.resetForm();
        },
        closeForm() {
            this.$emit('formClose', true);
        },
        myUploader(event) {
            this.form.image = event.files;
        },
        async updateRecord() {
            if (this.recId > 0) {
                var Vthis = this;
                this.loading = true;
                await this.axios.post('getAllVehicleTypes', { id: this.recId })
                    .then((response) => {
                        let responseStatus = this.printResponseResult(response, false);
                        if (responseStatus == 200) {
                            var tax_rates = response.data[0].tax_rates;
                            response.data[0].tax_rates = [];
                            response.data[0].status = response.data[0].status == 'Blocked' ? 0 : 1;
                            Vthis.form.fill(response.data[0]);
                            Vthis.image = false;
                            Vthis.showInstantPrice = false;
                            if (response.data[0].instant_price) {
                                Vthis.showInstantPrice = true;
                            }
                            if (response.data[0].images) {
                                Vthis.images = response.data[0].images;
                            }

                            if (tax_rates) {
                                var ids = tax_rates.toString().split(',');
                                Vthis.form.tax_rates = ids.map(Number);
                                console.log(Vthis.form.tax_rates);
                            }

                            if (this.isCopy) {
                                this.form.id = 0;
                            }
                        }
                    })
                    .catch((error) => {
                        this.printResponseResult(error);
                    })
                    .finally(() => {
                        Vthis.loading = false;
                    })
            }
        },
        async removeimage($id) {
            var Vthis = this;
            await Vthis.axios.post('deleteVehicleTypeImage', { id: this.form.id, image: $id })
                .then((response) => {
                    let responseStatus = this.printResponseResult(response, false);
                    if (responseStatus == 200) {
                        if (response) {
                            Vthis.showMessage('Deleted Successfully', 'success');
                            Vthis.images = Vthis.images.filter((image) => {
                                if (image.id != $id) {
                                    return image;
                                }
                            });
                        }
                        else {
                            Vthis.showMessage('Not deleted! Please try later', 'error');
                        }
                    }
                })
                .catch((error) => {
                    this.printResponseResult(error);
                })
                .finally(() => {
                })
        },
        confirmFileDeletion($event, $id) {
            this.$confirm.require({
                target: $event.currentTarget,
                message: 'Are you sure you want to proceed?',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.removeimage($id);
                },
                reject: () => {

                }
            });
        }
    },
    watch: {

    }
}
</script>
 
